<template>
  <div class="bg-blue full-height text-lightblue p-md">
    <back url="/resource" />
    <div class="flex items-center justify-between m-t-sm m-b-sm">
      <div class="fl-menu flex items-center">
        <span
          :class="[{ current: activeMenu === 1 }, 'pointer']"
          @click="activeMenu = 1"
          >待处理(10)</span
        >
        <span
          :class="[{ current: activeMenu === 2 }, 'pointer']"
          @click="activeMenu = 2"
          >调阅申请</span
        >
      </div>
    </div>
    <component :is="selectComp" />
  </div>
</template>

<script>
import Back from '@/components/Back';
import Pending from './components/Pending';
import Borrow from './components/Borrow';
export default {
  name: 'Apply',
  data() {
    return {
      activeMenu: 1,
      selectComp: 'Pending',
    };
  },
  components: {
    Back,
    Pending,
    Borrow,
  },
  mounted() {
    this.setMode();
  },
  computed: {},
  watch: {
    activeMenu(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.selectComp = newValue === 1 ? 'Pending' : 'Borrow';
      }
    },
  },
  methods: {
    setMode() {
      this.activeMenu = Number(this.$route.query.mode);
    },
  },
};
</script>

<style scoped></style>
