<template>
  <div>
    <a-modal
      :title="title"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div>
        <div class="m-b-sm">
          <span class="text-white block m-b-xs">申请原因:</span>
          <span class="text-lightblue"
            >由于XX原因，申请调阅摄像头，望批准！</span
          >
        </div>
        <div class="m-b-sm">
          <span class="text-white block m-b-xs">申请调阅时间:</span>
          <a-range-picker
            :placeholder="['开始时间', '结束时间']"
            show-time
          ></a-range-picker>
        </div>
        <div class="m-b-sm">
          <span class="text-white block m-b-xs">审批:</span>
          <a-radio-group name="radioGroup" v-model="value">
            <a-radio :value="1">
              同意
            </a-radio>
            <a-radio :value="2">
              驳回
            </a-radio>
          </a-radio-group>
        </div>
        <div class="m-b-sm">
          <span class="text-white block m-b-xs">分配摄像机:</span>
          <span class="text-lightblue flex items-center"
            ><i class="r-add m-r-sm" @click="addHandle"></i>已选
            <span class="text-line m-l-xs m-r-xs">15</span> 路摄像机</span
          >
        </div>
        <div class="m-b-sm">
          <span class="text-white block m-b-xs">备注:</span>
          <a-input placeholder="请填写" />
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ApplyDialog',
  props: {
    visible: {
      type: Boolean,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      confirmLoading: false,
      value: 1,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleOk() {
      this.ModalText = 'The modal will be closed after two seconds';
      this.confirmLoading = true;
      setTimeout(() => {
        this.confirmLoading = false;
        this.$emit('closeDialog');
      }, 2000);
    },
    handleCancel() {
      console.log('Clicked cancel button');
      this.$emit('closeDialog');
    },
    addHandle() {
      this.$emit('addDialog');
    },
  },
};
</script>

<style scoped></style>
