<template>
  <div>
    <a-modal
      title="选择摄像机"
      :visible="visible"
      width="90%"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
    >
      <div class="flex" style="height:360px;">
        <div class="f-l m-r-md">
          <cameral-route-com />
        </div>
        <div class="flex-one">
          <div class="search-box flex items-center m-b-md">
            <div class="flex text-white items-center m-r-xs">
              <a-input placeholder="桩号起始公里数" size="small" /><span
                class="m-l-xs m-r-xs"
                >~</span
              ><a-input placeholder="桩号终止公里数" size="small" />
            </div>
            <div class="m-r-xs">
              <a-select placeholder="路线" style="width: 120px;" size="small">
                <a-select-option value="jack">
                  Jack
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
            </div>
            <div class="m-r-xs">
              <a-select
                placeholder="管辖单位"
                style="width: 120px;"
                size="small"
              >
                <a-select-option value="jack">
                  Jack
                </a-select-option>
                <a-select-option value="lucy">
                  Lucy
                </a-select-option>
              </a-select>
            </div>
            <div>
              <a-button type="primary" class="m-r-sm btn-search" size="small">
                搜索
              </a-button>
              <a-button class="btn-reset" size="small">
                重置
              </a-button>
            </div>
          </div>
          <div class="fl-menu flex items-center">
            <span
              :class="[{ current: activeMenu === 1 }, 'pointer']"
              @click="activeMenu = 1"
              >全部</span
            >
            <span
              :class="[{ current: activeMenu === 2 }, 'pointer']"
              @click="activeMenu = 2"
              >已选（15）</span
            >
          </div>
          <div class="relative">
            <a-table
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              :columns="columns"
              :data-source="data"
              :pagination="paginationOpt"
              bordered
            >
            </a-table>
            <span class="absolute l0 text text-lightblue"
              >共计：100，已选：4</span
            >
          </div>
          <div class="flex items-center">
            <div class="wd p-w-md">
              <a-checkbox @change="onChange">
                选择所有数据
              </a-checkbox>
            </div>
            <a-button type="primary" class="m-r-sm btn-search">
              删除
            </a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import CameralRouteCom from '@/components/CameralRoute';
export default {
  name: 'CameralSelectCom',
  props: {
    visible: {
      type: Boolean,
    },
  },
  data() {
    return {
      confirmLoading: false,
      activeMenu: 1,
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 20, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          // this.searchCameraFrom(); //显示列表的接口名称
          // this.searchCameraFromTest();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          // this.searchCameraFrom();
          // this.searchCameraFromTest();
        },
      },
      columns: [
        {
          title: '排序',
          customRender: (text, record, index) =>
            `${(this.paginationOpt.current - 1) *
              this.paginationOpt.defaultPageSize +
              index +
              1}`,
        },
        {
          title: '管辖单位',
          dataIndex: 'organization',
          key: 'organization',
        },
        {
          title: '路线',
          dataIndex: 'route',
          key: 'route',
        },
        {
          title: '桩号',
          dataIndex: 'kmile',
          key: 'kmile',
        },
        {
          title: '兴趣点名称',
          dataIndex: 'potName',
          key: 'potName',
        },
        {
          title: '所属类别',
          dataIndex: 'category',
          key: 'category',
        },
        {
          title: '方向',
          dataIndex: 'direction',
          key: 'direction',
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
        },
      ],
      data: [
        {
          key: 0,
          organization: 'grass',
          route: 'G3京台高速',
          kmile: 'k1518+750',
          potName: '九华山隧道',
          category: '道路沿线',
          direction: '上行',
          status: '正常',
        },
      ],
      selectedRowKeys: [],
    };
  },
  computed: {},
  components: {
    CameralRouteCom,
  },
  watch: {},
  methods: {
    handleOk() {
      this.ModalText = 'The modal will be closed after two seconds';
      this.confirmLoading = true;
      setTimeout(() => {
        this.confirmLoading = false;
        this.$emit('closeCameralDialog');
      }, 2000);
    },
    handleCancel() {
      console.log('Clicked cancel button');
      this.$emit('closeCameralDialog');
    },
    onSelectChange(selectedRowKeys) {
      console.log('selectedRowKeys changed: ', selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    onChange(e) {
      console.log(`checked = ${e.target.checked}`);
    },
  },
};
</script>

<style scoped>
.f-l {
  width: 320px;
}
.text {
  bottom: 26px;
}
.wd {
  width: 40%;
}
</style>
