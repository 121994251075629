<template>
  <div class="full-width">
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="paginationOpt"
      bordered
    >
      <div slot="operation" slot-scope="text, record">
        <span
          class="icon-apply m-r-sm pointer"
          @click="editHandle(record)"
        ></span>
        <span class="icon-affix pointer" @click="viewHandle(record)"></span>
      </div>
    </a-table>
    <apply-dialog
      title="江苏"
      :visible="visible"
      @closeDialog="closeHandle"
      @addDialog="addHandle"
    />
    <cameral-select
      :visible="cameralSelectVisible"
      @closeCameralDialog="closeCameralHandle"
    />
  </div>
</template>

<script>
import ApplyDialog from './ApplyDialog';
import CameralSelect from './CameralSelect';
export default {
  name: 'Pending',
  data() {
    return {
      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 5, // 默认当前页显示数据的大小
        total: 20, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          // this.searchCameraFrom(); //显示列表的接口名称
          // this.searchCameraFromTest();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          // this.searchCameraFrom();
          // this.searchCameraFromTest();
        },
      },
      columns: [
        {
          title: '排序',
          customRender: (text, record, index) =>
            `${(this.paginationOpt.current - 1) *
              this.paginationOpt.defaultPageSize +
              index +
              1}`,
        },
        {
          title: '申请账号',
          dataIndex: 'account',
          key: 'account',
        },
        {
          title: '所属省份',
          dataIndex: 'province',
          key: 'province',
        },
        {
          title: '申请原因',
          dataIndex: 'reason',
          key: 'reason',
        },
        {
          title: '申请调阅时间',
          dataIndex: 'applyDeployDate',
          key: 'applyDeployDate',
        },
        {
          title: '申请时间',
          dataIndex: 'applyTime',
          key: 'applyTime',
        },
        {
          title: '操作',
          scopedSlots: {
            customRender: 'operation',
          },
        },
      ],
      data: [
        {
          key: 0,
          account: 'grass',
          province: '江苏省',
          reason: '由于XXXXX原因，申请调阅安徽临县摄像头，望批准！',
          applyDeployDate: '2020年3月31日11:06 ~ 2020年4月1日15:33',
          applyTime: '2020年3月31日11:06',
        },
      ],
      visible: false,
      cameralSelectVisible: false,
    };
  },
  computed: {},
  watch: {},
  components: {
    ApplyDialog,
    CameralSelect,
  },
  methods: {
    editHandle(row) {
      this.visible = true;
      console.error(row);
    },
    viewHandle(row) {
      console.error(row);
    },
    closeHandle() {
      this.visible = false;
    },
    addHandle() {
      this.cameralSelectVisible = true;
    },
    closeCameralHandle() {
      this.cameralSelectVisible = false;
    },
  },
};
</script>

<style scoped></style>
